//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    methods: { type: Array, default: () => [] },
    editedData: { type: [Array, Object], default: () => {} },
  },

  data() {
    return {
      data: {
        makerRoles: [],
        paymentMethods: [],
        limitFrom: undefined,
      },
    };
  },

  computed: {
    getPaymentMethods() {
      return this.$props.methods.map((method, index) => { return { id: index + 1, caption: method }; });
    },

    getMakerRoles() {
      return [{ id: 0, caption: this.$t('Trader') }, { id: 1, caption: this.$t('Merchant') }];
    },
  },

  mounted() {
    this.data.paymentMethods = this.$props?.editedData?.paymentMethods;
    this.data.limitFrom = this.$props?.editedData?.limitFrom;
    if (this.data.makerRoles.length !== 0) this.data.makerRoles = this.getMakerRoles.filter((elem) => { return this.$props?.editedData?.makerRoles.includes(elem.id); });
  },

  methods: {
    saveContent() {
      this.$emit('inputExchanger', this.data);
    },

    setPaymentMethods(list) {
      this.data.paymentMethods = list;
      this.saveContent();
    },

    setMakerRole(list) {
      this.data.makerRoles = list;
      this.saveContent();
    },

    setLimitFrom(limit) {
      this.data.limitFrom = Number(limit);
      this.saveContent();
    },
  },
};

