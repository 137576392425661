var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"section__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('div',{staticClass:"ml-10 mt-3"},[_c('v-btn',{attrs:{"color":"primary","height":"40px","outlined":""},on:{"click":_vm.openCreateRateModal}},[_vm._v(" "+_vm._s(_vm.$t('Create'))+" ")])],1)],1),_c('div',{staticClass:"section__table"},[_c('UiTable',{attrs:{"selection":_vm.selection,"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"fixed-header":"","height":"527px","show-extra":""},on:{"update:selection":function($event){_vm.selection=$event}},scopedSlots:_vm._u([{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex flex-wrap"},[(row.sourceSettings.length > 0)?_c('sourceCard',{attrs:{"source":row.sourceSettings}}):_c('span',[_vm._v(_vm._s(_vm.$t('Sources data not found')))])],1)]}},{key:"actions",fn:function(){return [_c('DropdownList',{attrs:{"config":_vm.actionsConfig},on:{"action":_vm.actionHandler}})]},proxy:true},{key:"customCell",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}},{key:"rate",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.fixedRate > 0 ? row.fixedRate : '-'))])]}},{key:"coefficient",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.coefficient > 0 ? row.coefficient : '-'))])]}},{key:"controls",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex"},[[_c('div',{staticClass:"d-flex align-center justify-end mr-2"},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","color":"warning"},on:{"click":function($event){return _vm.openModalEditRate(row)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)],[_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","color":"error"},on:{"click":function($event){return _vm.deleteRate(row)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]],2)]}}])})],1),_c('Modal',{attrs:{"data":_vm.editingRate.data,"is-creating":_vm.editingRate.isCreating},on:{"apply":_vm.applyEdit},model:{value:(_vm.editingRate.show),callback:function ($$v) {_vm.$set(_vm.editingRate, "show", $$v)},expression:"editingRate.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }