//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import constant from '~/const';

export default {
  data() {
    return {
      model: {
        takerPayIn: 0,
        takerPayOut: 0,
        makerPayIn: 0,
        makerPayOut: 0,
      },
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapState('tradersFees', ['data']),
  },

  watch: {
    data() {
      const {
        takerPayIn,
        takerPayOut,
        makerPayIn,
        makerPayOut,
      } = this.data;

      this.model.takerPayIn = takerPayIn;
      this.model.takerPayOut = takerPayOut;
      this.model.makerPayIn = makerPayIn;
      this.model.makerPayOut = makerPayOut;
    },
  },

  methods: {
    ...mapActions('common', ['confirmAction', 'setGeneralProgress', 'setSuccessNotification']),
    ...mapActions('tradersFees', ['loadData', 'editFees']),

    apply() {
      this.confirmAction({
        title: 'Are you sure you want to update fees?',
        callback: async () => {
          const { model } = this;
          this.setGeneralProgress(true);
          await this.editFees(model);
          await this.loadData();
          this.setGeneralProgress(false);
          this.setSuccessNotification('Fees updated successfully');
        },
      });
    },
  },
};
