//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import constant from '~/const';
import exchangerForm from './exchangerForm.vue';

export default {
  components: { exchangerForm },
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
    isCreating: { type: Boolean, default: false },
  },

  data() {
    return {
      exchanger: [],
      fixedRate: undefined,
      input: {
        fixedRate: undefined,
        sourceSettings: [],
      },
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapState('tradersRates', ['currencies', 'sources']),
    ...mapState('ratePaymentMethods', { methods: 'data' }),

    rateSources() {
      return this.sources.map((elem, index) => { return { id: index + 1, caption: elem }; });
    },

    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    defaultInput() {
      return {
        baseCurrency: '',
        quoteCurrency: '',
        sourceSettings: [],
        fixedRate: undefined,
        coefficient: undefined,
      };
    },

    isApplyPossible() {
      return this.input?.baseCurrency && this.input?.quoteCurrency && (this.exchanger.length > 0 || (this.fixedRate && this.input?.fixedRate));
    },
  },

  watch: {
    value(val) {
      if (val) {
        const { isCreating, defaultInput, data } = this;
        if (isCreating) {
          this.$set(this, 'input', defaultInput);
          this.exchanger = [];
          this.fixedRate = false;
        } else {
          this.fixedRate = data.fixedRate > 0;
          this.exchanger = data.sourceSettings.length > 0 ? this.rateSources.filter((rateSource) => this.data.sourceSettings.some((sourceSetting) => sourceSetting.name === rateSource.caption)) : [];
          const model = {};
          Object.keys(defaultInput).forEach((key) => {
            model[key] = data[key];
          });
          this.$set(this, 'input', model);
        }
      } else {
        this.clearFields();
      }
    },
  },

  methods: {
    apply() {
      const { input } = this;
      const filteredInput = Object.fromEntries(
        Object.entries(input).filter(([, value]) => {
          return value !== '' && value !== null && value !== undefined;
        }),
      );
      this.$emit('apply', { filteredInput });
    },

    clearExchangerData() {
      if (this.fixedRate) {
        this.exchanger = [];
        this.input.sourceSettings = [];
      } else {
        this.input.fixedRate = '';
      }
    },

    clearFields() {
      this.input.baseCurrency = '';
      this.input.quoteCurrency = '';
      this.input.sourceSettings = [];
      this.input.fixedRate = undefined;
      this.input.coefficient = undefined;
    },

    handleExchangerChange(value) {
      this.input.sourceSettings = this.input.sourceSettings.filter((setting) => value.includes(setting.name));
    },

    setExchangers(item, name) {
      const { isCreating } = this.$props;
      const index = this.input.sourceSettings.findIndex((elem) => {
        return elem.name === (name.caption || name);
      });
      if (isCreating) {
        if (index >= 0) {
          this.input.sourceSettings[index] = { ...item, name };
        } else {
          this.input.sourceSettings = [...this.input.sourceSettings, { ...item, name }];
        }
      } else {
        this.input.sourceSettings[index] = { ...item, name: name.caption };
      }
    },
  },
};
