//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Modal from './Modal.vue';

import tableConfig from './tableConfig';

export default {
  components: {
    Modal,
  },

  data() {
    return {
      editingLimit: {
        show: false,
        data: {},
        isCreating: false,
      },
      tableConfig,
      selection: [],
      loader: false,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('tradersLimits', ['data']),
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('tradersLimits', {
      loadDataAction: 'loadData',
      editLimitAction: 'editLimit',
      addLimitAction: 'addLimit',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    async applyEdit({ input }, currency) {
      const { isCreating } = this.editingLimit;
      const newData = {
        currency,
        min: Number(input.min),
        max: Number(input.max),
        payOutMin: Number(input.payOutMin),
        payOutMax: Number(input.payOutMax),
      };
      this.setGeneralProgress(true);
      this.loader = true;
      try {
        if (isCreating) {
          await this.addLimitAction({ limits: [newData] });
        } else {
          await this.editLimitAction({ limits: [newData] });
        }
        this.editingLimit.show = false;
        await this.loadDataAction({});
        if (isCreating) {
          this.setSuccessNotification('Limit created');
        } else {
          this.setSuccessNotification('Limit edited');
        }
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
      this.loader = false;
    },

    openModal(row, isCreate) {
      if (isCreate) {
        this.editingLimit.isCreating = true;
      } else {
        this.editingLimit.isCreating = false;
      }
      this.editingLimit.show = true;
      this.editingLimit.data = row;
    },
  },
};
