//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import Fees from '~/views/TradersFeesAndRates/Fees/TradersFeesBlock.vue';
import Rates from '~/views/TradersFeesAndRates/Rates/Table.vue';
import Limits from '~/views/TradersFeesAndRates/Limits/Table.vue';

export default {
  components: {
    Fees,
    Rates,
    Limits,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadFees(),
      this.loadRates(),
      this.loadLimits(),
      this.loadRateCurrencies(),
      this.loadRateSources(),
      this.loadCurrencies(),
      this.loadPaymentMethods(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('tradersFees', {
      loadFees: 'loadData',
    }),
    ...mapActions('tradersRates', {
      loadRates: 'loadData',
      loadRateCurrencies: 'loadRateCurrencies',
      loadRateSources: 'loadRateSources',
    }),
    ...mapActions('tradersLimits', {
      loadLimits: 'loadData',
    }),
    ...mapActions('tradersCurrencies', {
      loadCurrencies: 'loadData',
    }),
    ...mapActions('ratePaymentMethods', {
      loadPaymentMethods: 'loadData',
    }),
  },
};
