//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import constant from '~/const';

export default {
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
    isCreating: { type: Boolean, default: false },
  },

  data() {
    return {
      input: {},
      constant,
    };
  },

  computed: {
    ...mapState('tradersRequisites', ['userData']),
    ...mapState('tradersCurrencies', { currencies: 'data' }),

    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    defaultInput() {
      return {
        max: 0,
        min: 0,
        payOutMax: 0,
        payOutMin: 0,
      };
    },
    isApplyPossible() {
      return this.input?.max && this.input?.min && this.input?.payOutMax && this.input?.payOutMin;
    },
  },

  methods: {
    apply(currency) {
      const { input } = this;
      this.$emit('apply', { input }, currency);
      input.min = ' ';
      input.max = ' ';
      input.payOutMin = ' ';
      input.payOutMax = ' ';
    },
  },
};
