export default [
  {
    width: 17,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'text',
      value: (e) => e.currency,
    },
  },
  {
    width: 17,
    header: {
      type: 'text',
      caption: 'Pay In',
    },
    cell: {
      type: 'slot',
      name: 'payIn',
    },
  },
  {
    width: 17,
    header: {
      type: 'text',
      caption: 'Pay Out',
    },
    cell: {
      type: 'slot',
      name: 'payOut',
    },
  },
  {
    width: 17,
    header: {
      type: 'text',
      caption: 'Edit',
    },
    cell: {
      type: 'slot',
      name: 'edit',
    },
  },
];
